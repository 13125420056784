import axios from 'axios';
import apiurl from "./apiweb.js"
import { fetchAuthSession } from 'aws-amplify/auth'

const obj = {
    getData: async (params) =>
    axios({
        'method':'GET',
        'url':apiurl + "/misempresas",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
        'params': {
            ...params
        },
    })
};

export default obj;