import '@aws-amplify/ui-react/styles.css';
import AppMenu from './AppMenu';
import { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import apimisempresas from './api/apiweb-misempresas.js';
import Search from './views/buscadordocs/Search.js'; 
import apidevice from './api/apiweb-device.js';
import CierreCaja from './views/cierrecaja/CierreCaja.js'
import Conciliation from './views/conciliation/Conciliation.js';
import EstadoCajeros from './views/estado_cajeros/EstadoCajeros';
import ResumenDiarioBoletas from './views/resumendiarioboletas/Form.js';
import ResumenMensualBoletas from './views/resumenmensualboletas/Form.js';
import PosUserConfig from './views/posuserconfig/PosUserConfig.js';


function App({ signOut, user }) {
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const [empresas, setEmpresas] = useState(false);
  const [devices, setDevices] = useState(false);
  useEffect(() => {
    if(!empresas) {
      apimisempresas.getData({}).then(
          (data) => {
              setEmpresas(data.data.records);
          }
      ).catch(
          (error)=>{setEmpresas(true);}
      )
    }
    if(!devices) {
      // console.log(devices)
      apidevice.getAllDevices({}).then(
          (data) => {
              setDevices(data.data.records);
              // console.log(data.data.records);
          }
      ).catch(
          (error)=>{setDevices(true);}
      )
    }
  }
  , [empresas, devices]);
  


  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      <BrowserRouter>
        <AppMenu
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          empresas={empresas}
        />
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <header>
        {empresas && empresas?.map((e) => e.name)}
      </header>
      <Routes>
        <Route path="/" element={<Search devices={devices}/>} />
        <Route path="/search" element={<Search devices={devices}/>} />
        <Route path="/cierrecaja" element={<CierreCaja devices={devices}/>} />
        <Route path="/conciliacion" element={<Conciliation devices={devices}/>} />
        <Route path="/estado_cajeros" element={<EstadoCajeros />} />
        <Route path="/resumendiarioboletas" element={<ResumenDiarioBoletas/>} />
        <Route path="/resumenmensualboletas" element={<ResumenMensualBoletas/>} />
        <Route path="/usuariosposconfig" element={<PosUserConfig/>} />
      </Routes>
      <footer>
      </footer>
    </main>
      </BrowserRouter>
    </div>
  );
}

export default App;