import React, {useState} from "react";
import { Button, Modal, Table} from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import api from '../../api/apiweb-cierrecaja.js';

const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

const BotonGenerarCierre = ({device_id,showLoadingModal,closeLoadingModal,setMessageLoadModal}) => {
  const [generating, setGenerating] = useState(false);
  const generarCierreNuevo = () => {

    // console.log(setModalDescarga);
    showLoadingModal()
    api.nuevoCierreCaja({ device_id: device_id }).then(
      (data) => {
        if (__DEV__) { console.log(data); }
        setGenerating(false);
        closeLoadingModal();

      }
    ).catch(
      (error) => {
        setGenerating(false)
        setMessageLoadModal();
      }
    )
    
        
    setGenerating(true);
    
    
    console.log(device_id);
  }
  
  return (<Button variant="primary" onClick={() => generarCierreNuevo(device_id)} disabled={generating}>Generar<FaPlus className="ml-3"/></Button>);
};

const CierreCajaNuevo = props => <div>
      <Modal size="lg" show={props.show} onHide={props.onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Nuevo Cierre de Caja</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead><tr><th>Local</th><th>Cajero</th><th>Generar</th></tr></thead>
        <tbody>
          {props.devices && props.devices.map(e => 
          <tr key={e.device_id}>
            <td>{e.local}</td>
            <td>{e.name}</td>
            <td><BotonGenerarCierre device_id={e.device_id} showLoadingModal={props.showLoadingModal} closeLoadingModal={props.closeLoadingModal} setMessageLoadModal={props.setMessageLoadModal}/></td>
          </tr>
          )}
        </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>


    </div>;

export default CierreCajaNuevo;
