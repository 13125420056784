import React from 'react';
import readXlsxFile from 'read-excel-file'

import {Card, Button, Row, Col,Modal,Spinner} from 'react-bootstrap';


import conciliation from '../../api/apiweb-conciliation.js';



function Conciliation(props) {
  
  
  const [modalDescarga, setModalDescarga] = React.useState(false);
  const [modalMensaje, setmodalMensaje] = React.useState('');
  const [errorConcilation, seterrorConcilation] = React.useState(false);
  const closeModal = () =>{
    setModalDescarga(false);
  }
 
  const schema = {
      'fechaemision': {
        prop: 'fechaemision',
        required: true
      },
      'ticket': {
        prop: 'ticket',
        type: String,
        required: true
      },
    }

    const hiddenFileInput = React.useRef(null);
  
    const handleClick = event => {
      hiddenFileInput.current.click();
    };  
    
    const handleChange = async(event) => {

      const fileUploaded = event.target.files[0];
      // props.handleFile(fileUploaded);
      setmodalMensaje('Procesando');
      setModalDescarga(true);
      seterrorConcilation(false);
      readXlsxFile(fileUploaded,{ schema }).then(async({ rows, errors }) => {

        if(errors.length>0){
          console.log(errors);
          seterrorConcilation(true);
          setmodalMensaje('Archivo erroneo');
          return;
        }
        
        for (let index = 0; index < rows.length; index++) {
         
          if(rows[index].fechaemision instanceof Date){
            // console.log(element)
           rows[index].fechaemision =rows[index].fechaemision.toISOString().split('T')[0];
            // console.log('tipo fecha')
          }
        }
        // console.log(JSON.stringify(rows));
        
        try {
          const data=await conciliation(rows);
          setModalDescarga(false);
          seterrorConcilation(false)
          window.location=data;
          
        } catch (error) {
          console.log(error)
          setmodalMensaje('Error en la conciliación de documentos');
          seterrorConcilation(true)
          
        }

        hiddenFileInput.current.value = null;
        
        
        
    });
  }
    


  return (
    <>

    
    <div className="Conciliation">
    <Card >
        <Card.Header>Conciliación Documentos</Card.Header>
        <Card.Body>
            
            <Row>

            <Col className="mt-3 text-nowrap">
              <Button onClick={handleClick}>
                Subir archivo
              </Button>
              <input type="file" accept=".xlsx"
               ref={hiddenFileInput}
               onChange={handleChange}
               style={{display:'none'}} 
              />    
            </Col>

            <Col className="mt-3 "><a href={process.env.PUBLIC_URL+'/conciliacion.xlsx'} download="conciliacion_ejemplo.xlsx">Descargar ejemplo</a> </Col>
            
            </Row> 


            

            {/* <Row> <input

            type="file"

            value={selectedFile}

            onChange={(e) => {handleInputFile(e.target.files[0])}}

            /></Row> */}
            
        </Card.Body>
    </Card>
        <br/>
        
        <Modal show={modalDescarga} >
    <Modal.Header>
    <Modal.Title>Conciliación documentos</Modal.Title>
    </Modal.Header>
    <Modal.Body>{modalMensaje}
    {!errorConcilation && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}
    </Modal.Body>
    <Modal.Footer>
    {errorConcilation &&<Button variant="secondary" onClick={closeModal}>
        Close
    </Button>}
    
    </Modal.Footer>
    </Modal>
    </div>

    
</>


  );
}


export default Conciliation;
