import React, { useState } from 'react';
import {Card, Button, Row, Col, Form, FormControl, Table, Modal, Spinner} from 'react-bootstrap';
import { FaPlus, FaSearch, FaEraser } from 'react-icons/fa';
import api from '../../api/apiweb-cierrecaja.js';
import PrintCierreCaja from "./PrintCierreCaja";
import CierreCajaNuevo from "./CierreCajaNuevo";
var moment = require('moment-timezone');

function CierreCaja(props) {
  
  const [startDate, setStartDate] = useState(moment().tz("America/Santiago").format().slice(0, 10));
  const [endDate, setEndDate] = useState(""+startDate);
  const [searching, setSearching] = useState(false);
  const [responseData, setResponseData] = React.useState(null);
  const [modalDescarga, setModalDescarga] = React.useState(false);
  const [mensajeFallo, setMensajeFallo] = React.useState(false);
  const [modalDescargaMensaje, setModalDescargaMensaje] = React.useState('');
  const [cierreCajaData, setCierreCajaData] = React.useState(null);
  const [cierreCajaNuevoModal, setCierreCajaNuevoModal] = React.useState(false);

  const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

  const busqueda = async () => {

    // console.log('cierre caja busqueda')
    setSearching(true);
    setModalDescarga(true)
    setMensajeFallo(false)
    setModalDescargaMensaje('Procesando  ');
      api.getData({startDate:startDate,endDate:endDate}).then(
          (data) => {
              setModalDescarga(false);
              if (__DEV__) {console.log(data);}
              setResponseData(data.data.records);
              setSearching(false);
          }
      ).catch(
       (error)=>{
          setSearching(false);
          setModalDescargaMensaje('Error en la descarga');
          setMensajeFallo(true);
       }
  )
};

  const verCierreCaja = (e) => {

    const cierrecaja_id=e;
    // const cierrecaja_id = e.target.getAttribute('cierrecaja_id');
    
    
    console.log(cierrecaja_id);
  

    // if (true) {setCierreCajaData(cierreCajaDemo); return;  }

    if (cierrecaja_id!=null) {
      setModalDescarga(true)
    setMensajeFallo(false)
    setModalDescargaMensaje('Procesando  ');
      api.getCierreCaja({cierrecaja_id:cierrecaja_id}).then(
        (data) => {
            setModalDescarga(false);
            if (__DEV__) {console.log(data);}
            setCierreCajaData(data.data);
            setSearching(false);
        }
      ).catch(
          (error)=>{
            setSearching(false);
            setModalDescargaMensaje('Error en la descarga');
            setMensajeFallo(true);
         }
      )
    }
  }

  const closeModalCierreCaja = ()=> {
    setCierreCajaData(null);
  }

  const mostrarModalCrearCierre = () => {
    setCierreCajaNuevoModal(true);
  }

  const closeModalCierreCajaNuevo = () => {
    setCierreCajaNuevoModal(false);
  }

  const closeModal = () =>{
    setModalDescarga(false);
  }

  const showLoadingModal = () => {
    setModalDescarga(true);
  }

  const closeLoadingModal = () => {
    setModalDescarga(false);
  }

  const setMessageLoadModal = ()=> {
    setModalDescargaMensaje('Error al ingresar cierre caja');
    setMensajeFallo(true);
  }
  

  return (
    <>
    <div className="CierreCaja">
    <Card >
        <Card.Header>Cierre de Caja</Card.Header>
        <Card.Body>
            <Row>
                <Col sm={12} md={6} lg={3}><Form.Label>Desde</Form.Label><FormControl type="date" value={startDate} onChange={(e)=>setStartDate(e.target.value)}/></Col>
                <Col sm={12} md={6} lg={3}><Form.Label>Hasta</Form.Label><FormControl type="date" value={endDate} onChange={(e)=>setEndDate(e.target.value)}/></Col>
                <Col sm={12} md={6} lg={3}></Col>
                <Col sm={12} md={6} lg={3} className="mt-3 text-right text-nowrap"><Button onClick={mostrarModalCrearCierre} variant="warning">Nuevo Cierre <FaPlus className="ml-3"/></Button></Col>
            </Row>
            <Row>
                <Col className="mt-3 text-nowrap">
                    <Button variant="primary" disabled={searching} onClick={busqueda}>Buscar<FaSearch className="ml-3"/></Button>
                </Col>
                <Col></Col>
                <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={() => {setStartDate("");setEndDate("");} }>Limpiar<FaEraser className="ml-3"/></Button>&nbsp;
                </Col>
            </Row>
        </Card.Body>
    </Card>
        <br/>      
        <Card >
        <Card.Header>Resultados</Card.Header>
        <Card.Body>
        
        {responseData && responseData.length>0 && 
                  <Table bordered hover>
                  <thead>
                    <tr>
                    <th>Fecha Emision</th>
                    <th>Local</th>
                    <th>Cajero</th>
                    <th>Usuario</th>
                    <th>Secuencia</th>
                    <th>Cantidad Documentos</th>
                    <th>Monto Tarjeta</th>
                    <th>Monto Efectivo</th>
                    <th>Ver</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responseData.map( e => (
                      <tr key={e.cierrecaja_id}>
                        <td>{e.termino_fecha}</td>
                        <td>{e.local}</td>
                        <td>{e.device_name}</td>
                        <td>{e.createuser}</td>
                        <td>{e.secuencia_dia}</td>
                        <td>{e.cantidaddocumentos}</td>
                        <td>{e.montototaltarjeta}</td>
                        <td>{e.montototalefectivo}</td>
                        <td><FaSearch style={{ cursor: 'grab' }} onClick={() => verCierreCaja(e.cierrecaja_id)} /></td>
                      </tr>
                    )
                  )}
                  </tbody>
                  </Table>
        }
        </Card.Body>
    </Card>

    </div>
    {(cierreCajaData && <PrintCierreCaja 
    cierreCajaData={cierreCajaData}
    onHide={closeModalCierreCaja}
    />)}
    {(cierreCajaNuevoModal && <CierreCajaNuevo
    show={cierreCajaNuevoModal}
    onHide={closeModalCierreCajaNuevo}
    devices={props.devices}
    showLoadingModal={showLoadingModal}
    closeLoadingModal={closeLoadingModal}
    setMessageLoadModal = {setMessageLoadModal}
    />)}

<Modal show={modalDescarga} >
    <Modal.Header>
    <Modal.Title>Cierre caja</Modal.Title>
    </Modal.Header>
    <Modal.Body>{modalDescargaMensaje}
    {!mensajeFallo && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}
    </Modal.Body>
    <Modal.Footer>
    {mensajeFallo &&<Button variant="secondary" onClick={closeModal}>
        Close
    </Button>}
    </Modal.Footer>
    </Modal>
    </>
  );
}

export default CierreCaja;


/*
const cierreCajaDemo={
  "tenant_id":"155480c5-4f79-4fc1-ad97-5d918078311a",
"device_id":"LENOVO/R90MV0ZN/LAPTOP-HE21S7M3",
"created_at":"2021-02-20 17:14:07",
"createuser":"mparedes",
"termino_fecha":"2021-02-20",
"termino_hora":"17:14:12",
"cierrecaja_id":"154aaf0c-baba-4d58-9dd4-21d60a26d31d",
"secuencia_dia":1,
"cantidaddocumentos":0,
"montototalefectivo":0,
"montototaltarjeta":0,
"inicio_fecha":"2021-02-20",
"inicio_hora":"00:00:00",
"rut":1,
"local":"","device_name":"LENOVO/R90MV0ZN/LAPTOP-HE21S7M3","status":"active"};
*/
