import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { Card, Button, Row, Col, Form, FormControl, Modal, Spinner } from 'react-bootstrap';
import { FaDownload, FaSearch, FaEraser, FaFilePdf } from 'react-icons/fa';
import api from '../../api/apiweb-buscadordocs.js';
import { TableSimple } from 'react-pagination-table'; //TablePagination
import "./Search.scss"
import getPDF from '../../api/apiweb-getpdf';



function Search(props) {

  const [docType, setDocType] = useState("39");
  const [folio, setFolio] = useState("");
  const [ticket, setTicket] = useState("");
  const [cajero, setCajero] = useState({ label: "Todos", value: '' });
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [searching, setSearching] = useState(false);
  const [responseData, setResponseData] = React.useState(null);
  const [modalDescarga, setModalDescarga] = React.useState(false);
  const [mensajeFallo, setMensajeFallo] = React.useState(false);
  //const [modalMensajefallo, setModalMensajeFallo] = React.useState(null);
  const [modalDescargaMensaje, setModalDescargaMensaje] = React.useState('');
  const [deviceOptions, setDevicesOptions] = useState([]);
  const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [searchByHour, setsearchByHour] = useState(false);





  const searchDocument = async (rut, tipodocumento, folio, fechaEmision) => {

    // console.log(rut,tipodocumento,folio,fechaEmision);

    setModalDescarga(true);
    setModalDescargaMensaje('Procesando  ');

    try {
      await getPDF(rut, tipodocumento, folio, fechaEmision);
      setModalDescarga(false);
    } catch (error) {
      setModalDescargaMensaje('Error en la descarga');
      setMensajeFallo(true);
    }



    // if(!resp){

    // }
    // setModalDescarga(false);

  }


  const busqueda = async () => {
    setSearching(true);
    setModalDescarga(true)
    setMensajeFallo(false)
    setModalDescargaMensaje('Procesando  ');

    let params;



    if (searchByHour) {
      if ((startTime !== '' && endTime === '') || (startTime === '' && endTime !== '')) {
        setModalDescargaMensaje('Intervalo de horas no válido');
        setMensajeFallo(true);
        setStartTime("");
        setEndTime("");
        setSearching(false);
        return;
      }
      params = { tipodocumento: docType, folio: folio, ticket: ticket, startDate: startDate, endDate: startDate, device_id: cajero.value, startTime: startTime, endTime: endTime }

    }
    else {
      params = { tipodocumento: docType, folio: folio, ticket: ticket, startDate: startDate, endDate: endDate, device_id: cajero.value }

    }


    console.log(params);

    api.getData(params).then(
      (data) => {
        setModalDescarga(false);
        if (__DEV__) { console.log(data); }

        //   console.log('asignar boton')
        const resp = data.data.records.map(record => {

          // console.log('recordfechahora',record.fechahora.split(" ")[1]);
          if (record.fechahora != null) {
            record.fechahora = record.fechahora.split(" ")[1]
          }

          return { ...record, descargar: <Button onClick={() => { searchDocument(record.rut, record.tipodocumento, record.folio, record.fechaemision) }} variant="primary"> <FaFilePdf></FaFilePdf>    </Button> }
        });
        // console.log(resp)

        //   data.data.records.forEach(record => {
        //       console.log(record);
        //   })
        setResponseData(resp);
        setSearching(false);
      }
    ).catch(
      (error) => {
        setSearching(false);
        setModalDescargaMensaje('Error en la descarga');
        setMensajeFallo(true);
      }
    )
  };



  const descarga = () => {
    //cargar modal
    setModalDescarga(true)
    setMensajeFallo(false)
    setModalDescargaMensaje('Procesando  ');
    let params;

    if (searchByHour) {
      params = { tipodocumento: docType, folio: folio, ticket: ticket, startDate: startDate, endDate: startDate, device_id: cajero.value, startHour: startTime, endHour: endTime, download: 'csv' }

    }
    else {
      params = { tipodocumento: docType, folio: folio, ticket: ticket, startDate: startDate, endDate: endDate, device_id: cajero.value, download: 'csv' }

    }

    console.log(params);
    api.download(params, (state, message) => {
      if (state) {
        setModalDescarga(false)
        setModalDescargaMensaje('')
      }
      else {
        if (message === ('Error en la descarga')) {
          setModalDescargaMensaje(message)
          setMensajeFallo(true)
        } else {
          setModalDescargaMensaje(message)
        }
        setModalDescarga(true)

      }
    });

  };

  const closeModal = () => {
    setModalDescarga(false);
  }


  useEffect(() => {
    const devices = props.devices;
    const array = [{ label: 'Todos', value: '' }]
    // console.log(devices)
    if (Array.isArray(devices)) {
      devices.forEach(element => {
        // console.log(element.name)
        const label = element.name;
        const value = element.device_id;
        array.push({ label, value });

      })
      // console.log(array);

      setDevicesOptions(array)
    }


  }, [props.devices])


  return (
    <>


      <div className="Search">
        <Card >
          <Card.Header>Búsqueda DTE Emitidos</Card.Header>
          <Card.Body>
            <Card.Text></Card.Text>
            <Row>
              <Col sm={12} md={6} lg={3}><Form.Label>Tipo&nbsp;Documento</Form.Label><FormControl as="select" value={docType} multiple={false} onChange={(e) => setDocType(e.target.value)}>
                <option value="">Todos</option>
                <option value="39">Boleta Electrónica</option>
                <option value="41">Boleta Exenta Electrónica</option>
              </FormControl></Col>
              <Col sm={12} md={6} lg={3}><Form.Label>Cajero</Form.Label>
                <Select options={deviceOptions}
                  value={cajero}
                  onChange={(e) => { setCajero(e) }} />
              </Col>

              <Col sm={12} md={6} lg={3}><Form.Label>Folio</Form.Label><FormControl value={folio} onChange={(e) => setFolio(e.target.value)} /></Col>
              <Col sm={12} md={6} lg={3}><Form.Label>Ticket</Form.Label><FormControl value={ticket} onChange={(e) => setTicket(e.target.value)} /></Col>


            </Row>
            <Row>
              <Col sm={6} md={3} lg={3}><Form.Label>{!searchByHour && 'Desde '} {searchByHour && 'Fecha'}</Form.Label><FormControl type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /></Col>
              {!searchByHour && <Col sm={6} md={3} lg={3}><Form.Label>Hasta</Form.Label><FormControl type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /></Col>}
              {searchByHour && <Col sm={6} md={3} lg={3}><Form.Label>Hora Inicio</Form.Label><FormControl type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)}></FormControl></Col>}
              {searchByHour && <Col sm={6} md={3} lg={3}><Form.Label>Hora Fin</Form.Label><FormControl type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)}></FormControl></Col>}

              <Col sm={12} md={12} lg={12}><Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Buscar por hora"

                onChange={(e) => setsearchByHour(e.target.checked)}
              /></Col>


            </Row>
            <Row>
              <Col className="mt-3 text-nowrap">
                <Button variant="primary" disabled={searching} onClick={busqueda}>Buscar<FaSearch className="ml-3" /></Button>
              </Col>
              <Col className="mt-3 text-nowrap"><Button onClick={descarga}> Descargar <FaDownload color={"white"}></FaDownload></Button></Col>
              <Col></Col>
              <Col className="mt-3 text-right text-nowrap">
                <Button variant="secondary" onClick={() => { setStartDate(""); setEndDate(""); setDocType(""); setFolio(""); setStartTime(""); setEndTime("") }}>Limpiar<FaEraser className="ml-3" /></Button>&nbsp;
              </Col>
            </Row>

            {/* <Row> <input

            type="file"

            value={selectedFile}

            onChange={(e) => {handleInputFile(e.target.files[0])}}

            /></Row> */}

          </Card.Body>
        </Card>
        <br />
        <Card >
          <Card.Header>Resultados</Card.Header>
          <Card.Body>
            {responseData && responseData.length > 0 &&
              <>
                <TableSimple
                  headers={["Folio", "Ticket", "Tipo Documento", "Fecha Emision", "Valor Neto", "Valor IVA", "Valor Exento", "Valor Total", "Hora", "Descargar"]}
                  data={responseData}

                  columns="folio.ticket.tipodocumento.fechaemision.valorneto.valoriva.valorexento.valortotal.fechahora.descargar"
                  perPageItemCount={10}
                  partialPageCount={3}
                  totalCount={responseData.length}
                  arrayOption={[['size', 'all', ' ']]}
                  nextPageText="Siguiente"
                  prePageText="Anterior"
                />
              </>
            }
          </Card.Body>
        </Card>
      </div>

      <Modal show={modalDescarga} >
        <Modal.Header>
          <Modal.Title>Búsqueda boletas</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDescargaMensaje}
          {!mensajeFallo && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}
        </Modal.Body>
        <Modal.Footer>
          {mensajeFallo && <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>}
        </Modal.Footer>
      </Modal>
    </>


  );
}


export default Search;
