import React, { useState } from 'react';
import {Card, Button, Row, Col, Form, FormControl, Modal, Spinner} from 'react-bootstrap';
import { FaDownload, FaSearch, FaEraser } from 'react-icons/fa';
import api from '../../api/apiweb-resumenmensualboletas.js';
import {TableSimple } from 'react-pagination-table'; //TablePagination
import "./styles.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import { useEffect } from 'react';
registerLocale('es', es)




function Search() {
  const [docType, setDocType] = useState("39");
//   const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [searching, setSearching] = useState(false);
  const [modalDescarga, setModalDescarga] = React.useState(false);
  const [mensajeFallo, setMensajeFallo] = React.useState(false);
  const [modalDescargaMensaje, setModalDescargaMensaje] = React.useState('');
  const [responseData, setResponseData] = React.useState(null);
  const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

  const [totalResumen, setTotalResumen] = useState(null)
//   const [totalCantidad, settotalCantidad] = useState(0);
//   const [totalValorNeto, settotalValorNeto] = useState(0);
//   const [totalValorIva, settotalValorIva] = useState(0);
//   const [totalValorExento, settotalValorExento] = useState(0);
//   const [totalValorTotal, settotalValorTotal] = useState(0);

  const busqueda = async (startDate) => {


    const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1).toISOString().split('T')[0];
    console.log(firstDay); 

    const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).toISOString().split('T')[0];
    console.log(lastDay); 
    // return true;
    setModalDescarga(true)
    setMensajeFallo(false)
    setModalDescargaMensaje('Procesando  ');
    setSearching(true);
        api.getData({docType:docType,startDate:firstDay,endDate:lastDay}).then(
            (data) => {
                if (__DEV__) {console.log(data.data.records);}
                
                setModalDescarga(false);
                setResponseData(data.data.records);
                setSearching(false);
            }
        ).catch(
            (error)=>{
                setSearching(false);
                setModalDescargaMensaje('Error en la descarga');
                setMensajeFallo(true);
            }
        )
  };

  const descarga = async () => {
    
    const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1).toISOString().split('T')[0];
    // console.log(firstDay); 

    const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).toISOString().split('T')[0];
    // console.log(lastDay);
    setModalDescarga(true)
    setMensajeFallo(false)
    // setSearching(true);
    setModalDescargaMensaje('Procesando  ');
    api.download({docType:docType,startDate:firstDay,endDate:lastDay},setModalDescarga)};

  const closeModal = () =>{
    setModalDescarga(false);
  }

  const [startDate, setStartDate] = useState(new Date());
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Col ><FormControl onClick={onClick} ref={ref} value={value} onChange={(date) => setStartDate(date)} /></Col>
      
    
  ));

  const currency = function(number){// para parsear numero a formato de moneda
    return new Intl.NumberFormat('es-IN').format(number);
};

  useEffect(() => {
    console.log('aquii')
    // return true;
    if(responseData!=null) {



        const money = 10000;
        
        console.log(currency(money));

        console.log(responseData.length)
        var [total,cantidad,exento,iva,neto ] = new Array(5).fill(0);

        responseData.forEach(element => {
            neto = neto+ parseInt(element.valorneto);
            exento = exento + parseInt(element.valorexento);
            cantidad= cantidad + parseInt(element.cantidad);
            iva= iva + parseInt(element.valoriva);
            total= total + parseInt(element.valortotal);  
            element.valorneto=currency(element.valorneto);    
            element.valorexento=currency(element.valorexento);
            element.valoriva=currency(element.valoriva); 
            element.valortotal=currency(element.valortotal);       
            
        });
        neto=currency(neto);
        iva=currency(iva);
        exento=currency(exento);
        total=currency(total);
        var array= []
        const object = {cantidad,neto,exento,iva,total};
        array.push(object);
        setTotalResumen(array);
        console.log(array);

        // console.log(cantidad,neto,total);
    }
   
    // console.log('actualizar resumen')
  
    
  }, [responseData])

  return (
    <>
    <div className="Search">
    <Card >
        <Card.Header>Resumen Mensual Documentos Emitidos</Card.Header>
        
        <Card.Body>
            <Card.Text></Card.Text>
            <Row>
                <Col sm={12} md={12} lg={4}><Form.Label>Tipo&nbsp;Documento</Form.Label><FormControl as="select" value={docType} multiple={false} onChange={(e)=> setDocType(e.target.value)}>
                <option value="">Todos</option>
                <option value="39">Boleta Electrónica</option>
                <option value="41">Boleta Exenta Electrónica</option>
                </FormControl></Col>
                <div>
                <Col sm={12} md={8} lg={8}>
                <Form.Label>Mes</Form.Label>
                
                <DatePicker selected={startDate} 
                onChange={(date) => setStartDate(date)} 
                dateFormat="MM/yyyy"
                showFourColumnMonthYearPicker
                showMonthYearPicker
                showFullMonthYearPicker                
                locale="es"
                customInput={<CustomInput />}
                /></Col></div>
                

            </Row>       
            <Row>

                <Col className="mt-3 text-nowrap">
                    <Button variant="primary" disabled={searching} onClick={()=>busqueda(startDate,endDate)}>Buscar<FaSearch className="ml-3"/></Button>
                </Col>
                <Col className="mt-3 text-nowrap"><Button onClick={descarga}> Descargar <FaDownload color={"white"}></FaDownload></Button></Col>
                <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={() => {setStartDate("");setEndDate("");setDocType("")} }>Limpiar<FaEraser className="ml-3"/></Button>&nbsp;
                </Col>
            </Row>
            
        </Card.Body>
    </Card>
        <br/>
        <Card >
        <Card.Header>Resultados </Card.Header>
        <Card.Body>
        {responseData && responseData.length>0 && 
        <>
        <TableSimple
        className="resumenmensualboletas"
        headers={ ["Tipo Documento", "Fecha Emision",  "Cantidad", "Valor Neto","Valor IVA","Valor Exento","Valor Total" ] }
        data={ responseData }
        columns="tipodocumento.fechaemision.cantidad.valorneto.valoriva.valorexento.valortotal"
        perPageItemCount={ 10 }
        partialPageCount={ 3 }
        totalCount={ responseData.length }
        arrayOption={ [['size', 'all', ' ']] }
        nextPageText="Siguiente"
        prePageText="Anterior"
       
        />
            </>
        }
                </Card.Body>
                
    </Card>
    <hr/>
    <Card>
    <Card.Header>Total Mensual</Card.Header>
        <Card.Body>
    {totalResumen && totalResumen.length>0 && 
            <>
            <TableSimple
            className="resumenmensualboletas"
            headers={ [ "Cantidad", "Valor Neto","Valor IVA","Valor Exento","Valor Total" ] }
            data={ totalResumen }
            columns="cantidad.neto.iva.exento.total"
            perPageItemCount={ 10 }
            partialPageCount={ 3 }
           
            arrayOption={ [['size', 'all', ' ']] }
          
            />
                </>     
            }
        </Card.Body>
    </Card>
    </div>

<Modal show={modalDescarga} >
<Modal.Header>
<Modal.Title>Resumen Mensual Documentos</Modal.Title>
</Modal.Header>
<Modal.Body>{modalDescargaMensaje}
{!mensajeFallo && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}
</Modal.Body>
<Modal.Footer>
{mensajeFallo &&<Button variant="secondary" onClick={closeModal}>
    Close
</Button>}
</Modal.Footer>
</Modal>
</>
  );
}


export default Search;
