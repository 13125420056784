import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"
// import FileDownload from 'js-file-download';

const obj = {
    getAllPOSUser: async () => {
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        return axios.get(apiurl + "posuser", {
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            crossdomain: true
        });
    },
    addPOSUser: async (params) => {
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        return axios.post(apiurl + "posuser",
            { 
                ...params 
            }
            ,
             {
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            crossdomain: true
        });
    },
    deletePOSUser: async (posuser) => {
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        return axios.delete(`${apiurl}posuser`, {
            params: {
                posuser: posuser
            },
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            crossdomain: true
        });
    },
    updatePOSUser: async (params) => {
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        return axios.put(apiurl + "posuser",
            { 
                ...params 
            }
            ,
            {
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            crossdomain: true
        });}
};

export default obj;