import EstadosConexion from './EstadosConexion';

export default function estadoConexionSegunLastCheck(last_check) {
    console.log("last_check",last_check);
    const fechaActual = new Date();
    const fechaUltimoCheck = new Date(last_check);
    const diferencia = fechaActual - fechaUltimoCheck;
    const diferenciaMinutos = Math.round(diferencia / 60000);
    if (diferenciaMinutos > 60 * 8) {
        return EstadosConexion.SinConexion;
    } else if (diferenciaMinutos > 60 * 1) {
        return EstadosConexion.Alerta;
    }
    return EstadosConexion.Conectado;
}
