import React  from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import EstadoLocal from './EstadoLocal';
import estadoCajeros from '../../api/apiweb-estadoCajeros';
import estadoConexionSegunLastCheck from './estadoConexionSegunLastCheck';

function EstadoCajeros() {
    let [dataLocales,setDataLocales] = useState([]);

    useEffect(() => {
        estadoCajeros.getEstadoCajeros().then((response) => {
            setDataLocales(agrupaPorLocal(response.data));
        });
    }, []);

    const agrupaPorLocal = (data) => {
        const locales = data.reduce((acc, item) => {
            const local = item.local;
            if (!acc[local]) {
            acc[local] = [];
            }
            acc[local].push(item);
            return acc;
        }, {});
        return Object.keys(locales).map((local) => ({
            name: local,
            cajas: locales[local].map((device)=>({...device, estado: estadoConexionSegunLastCheck(device.lastcheck)})),
        }));
    }
    

    return (
        <div className="EstadoCajeros">
        <Card >
            <Card.Header>Estado General</Card.Header>
            <Card.Body>
                <Card.Text>
                    <Row className="square border">
                        <Col md="3" >Local</Col>
                        <Col>
                            <Row >
                                <Col >Caja</Col>
                                <Col >Conexión</Col>
                                <Col >Disponibles / Requeridos</Col>
                                <Col  className="text-right">Ultimo Folio Emitido</Col>
                                <Col >Estado</Col>
                            </Row>
                        </Col>
                    </Row>
                    {dataLocales.map((local) => (<EstadoLocal local={local}/>)  )}
                </Card.Text>
            </Card.Body>
        </Card>
        </div>
    )
}

export default EstadoCajeros;
