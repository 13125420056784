import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"
// import FileDownload from 'js-file-download';

const obj = {
    
    getData: async (params,callback) =>
    axios({
        'method':'GET',
        'url':apiurl + "/cierrecaja",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
        'params': {
            ...params
        },
    }),
    getCierreCaja: async (params) =>
    axios({
        'method':'GET',
        'url':apiurl + "/cierrecaja/"+params.cierrecaja_id,
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
    }),
    nuevoCierreCaja: async (params) =>
    axios({
        'method':'POST',
        'url':apiurl + "/cierrecaja",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
        'params': {
            ...params
        },
    }),
};

export default obj;