import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"

const conciliation = async(rows) => {
    // console.log(JSON.stringify(rows));
    // console.log(fcallbacks);
    // const {setModalDescarga,setmodalDescargaMensaje,setMensajeFallo}=fcallbacks;
    return new Promise(async(resolve, reject) => {
        try {
            const result = await axios
            .post(apiurl+"conciliation", {
            rows: JSON.stringify(rows),
            },{
            'headers': {
                'content-type':'application/json',
                'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
            },
            'crossdomain': true})
            resolve(result.data)
                        
            
            
        } catch (error) {
            
            console.log(error);
            reject(error)
            
        }
    })
}

export default conciliation;