import { Col, Row } from "react-bootstrap";
import EstadosConexion from './EstadosConexion';
import {BsWifi, BsWifiOff, BsWifi1, BsBatteryFull, BsBatteryHalf, BsBattery} from "react-icons/bs";

function iconoParaEstado(estado) {
    switch (estado) {
        case EstadosConexion.Conectado:
            return <BsWifi />
        case EstadosConexion.SinConexion:
            return <BsWifiOff color="red"/>
        case EstadosConexion.Alerta:
            return <BsWifi1 color="orange"/>
        default:
            return <BsWifi />
    }
}

function iconoParaFolios(folios_disponibles, folios_requeridos) {
    if (folios_disponibles < folios_requeridos) {
        return <BsBattery color="red"/>
    } else if (folios_disponibles < folios_requeridos*1.1) {
        return <BsBatteryHalf color="orange"/>
    }
    return <BsBatteryFull />
}

function EstadoCaja(props) {
    const caja=props.caja;
    return (
        <Row className="square border ">
            <Col>{caja.device_name}</Col>
            <Col>{caja.estado}</Col>
            <Col>{caja.folios_disponibles} / {caja.folios_requeridos}</Col>
            <Col className="text-right">{caja.ultimo_folio}</Col>
            <Col>{iconoParaEstado(caja.estado)} {iconoParaFolios(caja.folios_disponibles,caja.folios_requeridos)}</Col>
        </Row>
    )
}

export default EstadoCaja;