import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"

const estadoCajeros = {
    getEstadoCajeros: async () =>
    axios({
        'method':'GET',
        'url':apiurl + "/estado_cajeros",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
    })
};

export default estadoCajeros;