import React, { useCallback, useEffect, useState } from 'react';
import { Card, Button, Form, Modal } from 'react-bootstrap';
import { TableSimple } from 'react-pagination-table'; // Asegúrate de que está importado
import api from '../../api/apiweb-posUser.js';
import "./PosUserConfig.scss";
import { FaCog, FaEye, FaEyeSlash, FaRegTrashAlt } from 'react-icons/fa';

function PosUserConfig(props) {
  const [responseData, setResponseData] = useState([]);
  const [modalAddUser, setModalAddUser] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para controlar el modal de espera
  const [errorMessage, setErrorMessage] = useState(null); // Estado para controlar el mensaje de error
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // 


  const fetchData = useCallback(async () => {
    try {
      const data = await api.getAllPOSUser();

      const resp = data.data.body
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        })
        .map(record => {

          return {
            ...record,
            eliminar: <Button onClick={() => handleDeleteUser(record.posuser)} variant="primary">
              <FaRegTrashAlt />
            </Button>,
            actualizar: <Button onClick={() => handleEditUser(record)} variant="primary">
              <FaCog />
            </Button>
          };
        });

      // console.log(resp)
      setResponseData(resp);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (confirmPassword !== value) {
      setError("Las contraseñas no coinciden");
    } else {
      setError(null);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    if (password !== value) {
      setError("Las contraseñas no coinciden");
    } else {
      setError(null);
    }
  };



  const addPOSUser = async (event) => {
    event.preventDefault();
    

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }

    setLoading(true);
    if (name && username && password) {
      const params = { name, username, password };
      try {
        await api.addPOSUser(params);
        setModalAddUser(false);
        fetchData();
      } catch (error) {
        console.log(error);
        setErrorMessage("Error al agregar el usuario. Inténtalo de nuevo.");
      } finally {
        setLoading(false);
        setName("");
        setUserName("");
        setPassword("");
        setConfirmPassword("");
      }
    }
  };

  const handleEditUser = (user) => {

    setName(user.name);
    setUserName(user.posuser);
    setPassword(user.password)
    setConfirmPassword(user.password)
    setModalEditUser(true);
    setShowPassword(false);
    setShowConfirmPassword(false);

  };

  const updatePOSUser = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }
    const params = { name, username, password };

    try {
      await api.updatePOSUser(params);
      setModalEditUser(false);
      fetchData();
    } catch (error) {
      setErrorMessage("Error al actualizar el usuario. Inténtalo de nuevo.");
      console.log(error);
    }
    finally {
      setName("");
      setUserName("");
      setPassword("");
      setConfirmPassword("");
    }
  };

  const handleDeleteUser = (posuser) => {
    setUserToDelete(posuser);
    setModalConfirmDelete(true);
  };

  const confirmDeleteUser = async () => {
    setLoading(true);
    try {
      await api.deletePOSUser(userToDelete);
      fetchData();
      setModalConfirmDelete(false);
    } catch (error) {
      setErrorMessage("Error al eliminar usuario. Inténtalo de nuevo.");
      setModalConfirmDelete(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showModalAddUser = () => {
    setName("");
    setUserName("");
    setPassword("");
    setConfirmPassword("");
    setModalAddUser(true);
    setShowPassword(false);
    setShowConfirmPassword(false);
  }

  const filteredData = responseData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.posuser.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchData();

  }, [fetchData]);

  return (
    <>
      <Card>
        <Card.Header>Configuración Usuarios POS</Card.Header>
        <Card.Body>
          <Button onClick={() => showModalAddUser()}>Agregar Usuario</Button>
          <Form.Control
            type="text"
            placeholder="Buscar usuario"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="my-3"
          />
          <TableSimple
            headers={["Nombre", "Usuario", "Eliminar", "Actualizar"]}
            data={filteredData}
            columns="name.posuser.eliminar.actualizar"
            perPageItemCount={10}
            partialPageCount={3}
            totalCount={filteredData.length}
            arrayOption={[['size', 'all', ' ']]}
            nextPageText="Siguiente"
            prePageText="Anterior"

          />
        </Card.Body>
      </Card>

      {/* Modal para agregar usuario */}
      <Modal show={modalAddUser}>
        <Modal.Header>
          <Modal.Title>Agregar nuevo usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addPOSUser}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa el nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa el usuario"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Contraseña"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <div className="input-group">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirmar contraseña"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <Button
                variant="outline-secondary"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
              </div>
            </Form.Group>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <Button variant="primary" type="submit" disabled={!!error}>
              Agregar
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalAddUser(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para editar usuario */}
      <Modal show={modalEditUser} onHide={() => setModalEditUser(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={updatePOSUser}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Contraseña</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirmar contraseña"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </div>
            </Form.Group>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <Button variant="primary" type="submit" disabled={!!error}>
              Actualizar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>


      {/* Modal de confirmación para eliminar usuario */}
      <Modal show={modalConfirmDelete} onHide={() => setModalConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que quieres eliminar este usuario? Esta acción no se puede deshacer.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalConfirmDelete(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDeleteUser}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={loading} centered>
        <Modal.Body>
          <div style={{ textAlign: 'center' }}>
            <p>Por favor, espera...</p>
            <div role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={!!errorMessage} onHide={() => setErrorMessage(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorMessage(null)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
}

export default PosUserConfig;
