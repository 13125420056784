import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth'
import apiurl from "./apiweb.js";


const getPDF = async (rut,tipodocumento,folio,fechaEmision) => {
        // console.log(rut,tipodocumento,folio,fechaEmision);
        const params = {rut,tipodocumento,folio,fechaEmision}
        console.log(params);

        return new Promise(async (resolve, reject) => {
            // try {
            //     axios({
            //         'method':'GET',
            //         'url':apiurl+"generarpdf",
            //         'headers': {
            //             'content-type':'application/json',
            //             'Authorization':"Bearer " + (await Auth.currentSession()).getIdToken().getJwtToken()
            //         },
            //         'crossdomain': true,
            //         'params': {
            //             ...params
            //         },
            //     }).then((data)=>{
            //         console.log(data);
            //         // resolve(data);
            //         window.location=data.data;
            //         resolve(true);
            //     })
                
            // } catch (error) {
            //     console.log(error);
            //     reject(error);
            // }

            try {
              const resp = await axios({
                method: "GET",
                url: apiurl + "generarpdf",
                headers: {
                  "content-type": "application/json",
                  'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
                },
                crossdomain: true,
                params: {
                  ...params,
                },
              });
              console.log(resp);
              window.location=resp.data;
              resolve(resp);
            } catch (error) {
                console.log(error);
                reject(error);
            } 

            
        })

}

export default getPDF; 
