import React from "react";
import ReactToPrint from "react-to-print";
import { Button, Modal} from 'react-bootstrap';

const pageStyle = `
  @page {
    size: 70mm 120mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1.$2");
  return x;
}

class CierreCajaView extends React.Component {
  render() {
    return (
        <pre> 
          CIERRE DE CAJA: {this.props.cierreCajaData.termino_fecha} {this.props.cierreCajaData.termino_hora}<br/>
          Anterior:       {this.props.cierreCajaData.inicio_fecha} {this.props.cierreCajaData.inicio_hora}<br/>
          {this.props.cierreCajaData.createuser && <>Usuario: {this.props.cierreCajaData.createuser}<br/></>}
          {this.props.cierreCajaData.local && <>Local: {this.props.cierreCajaData.local}<br/></>}
          Cajero: {this.props.cierreCajaData.device_name}<br/>
          Numero Cierre: {this.props.cierreCajaData.secuencia_dia}<br/>
          <br/>
          Documentos Emitidos:  {(""+this.props.cierreCajaData.cantidaddocumentos).padStart(12)}<br/>
          Folios:      Inicial -       Final<br/>
          {this.props.cierreCajaData.rangos.map(e=><>Boleta:  {("" + e.folio_inicio).padStart(11)} - {("" +e.folio_fin).padStart(11)}<br/></>)}
          
          <br/>
          Total Efectivo:       {numberWithCommas(this.props.cierreCajaData.montototalefectivo).padStart(12)}<br/>
          Total Tarjetas:       {numberWithCommas(this.props.cierreCajaData.montototaltarjeta).padStart(12)}<br/>
          Total Ventas:         {numberWithCommas(this.props.cierreCajaData.montototaltarjeta+this.props.cierreCajaData.montototalefectivo).padStart(12)}<br/>
        </pre>
    );
  }
}

class PrintCierreCaja extends React.Component {
  render() {
    return (
      <div>
<Modal
 show={this.props.cierreCajaData && this.props.cierreCajaData!=null}
 onHide={this.props.onHide}
 animation={false} 
    >
      <Modal.Header closeButton>
        <Modal.Title>Cierre de Caja</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
      <CierreCajaView cierreCajaData={this.props.cierreCajaData} ref={(el) => (this.componentRef = el)} />
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>Cerrar</Button>
        <ReactToPrint
          trigger={() => <Button variant="primary">Imprimir</Button>}
          content={() => this.componentRef}
          pageStyle={pageStyle}
          copyStyles={false}
        />
      </Modal.Footer>
    </Modal>
        
      </div>
    );
  }
}

export default PrintCierreCaja;
