import { Col, Row } from "react-bootstrap";
import EstadoCaja from "./EstadoCaja";

function EstadoLocal(props) {
    const local = props.local;
    const local_name = local.name || 'Sin Local';

    return <Row className="square border">
        <Col md="3" className="square border ">{local_name}</Col>
        <Col className="square border">
            {local.cajas.map((caja) => (
                <EstadoCaja caja={caja} />
            ))}
        </Col>
        </Row>
}
export default EstadoLocal;