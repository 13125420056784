import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"

const obj = {
    getData: async (params) =>
    axios({
        'method':'GET',
        'url':apiurl + "/buscador",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
        'params': {
            ...params
        },
    }),
    download: async (params,callback) => {        
            const method = 'GET';
            const url = apiurl + '/buscador'; // aquí la funcion debe estar en una ruta
            // console.log('Intentando descargar')
            // console.log(params);
            axios
              .request({
                url,
                method,
                'headers': {
                    'content-type':'application/json',
                    'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
                },        
               // crossdomain: true,
                params: { ...params},
              })
              .then(({ data }) => {
                //console.log(data.Link)
                window.location=data.Link;
                if (callback){ 
                  callback(true, '') }
              }).catch(err => {callback(false,'Error en la descarga')});
          
    }
};

export default obj;