import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import FileDownload from 'js-file-download';
import apiurl from "./apiweb.js"

const obj = {
    getData: async (params) =>
    axios({
        'method':'GET',
        'url':apiurl + "resumentotal",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
        'params': {
            ...params
        },
    }),
    download: async (params,setModalDescarga) => {
        axios({
            'method':'GET',
            'url':apiurl + "resumentotal",
            'headers': {
                'content-type':'application/json',
                'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
            },
            'crossdomain': true,
            'params': {
                download:"csv",
                ...params
            },
            responseType: 'blob', // important
        }).then((response)=>{
            FileDownload(response.data, 'documentos'+ Date.now()+'.csv');
            setModalDescarga(false);
        });
    }
};

export default obj;