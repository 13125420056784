import React, { useState } from 'react';
import {Card, Button, Row, Col, Form, FormControl, Modal, Spinner} from 'react-bootstrap';
import { FaDownload, FaSearch, FaEraser, FaCalendar } from 'react-icons/fa';
import api from '../../api/apiweb-resumendiarioboletas.js';
import {TableSimple } from 'react-pagination-table'; //TablePagination
import "./styles.scss"

function Search() {
  const [docType, setDocType] = useState("39");
  const [folio, setFolio] = useState("");
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [searching, setSearching] = useState(false);
  const [modalDescarga, setModalDescarga] = React.useState(false);
  const [mensajeFallo, setMensajeFallo] = React.useState(false);
  const [modalDescargaMensaje, setModalDescargaMensaje] = React.useState('');
  const [responseData, setResponseData] = React.useState(null);
  const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

  const ayer = () => {
    let fecha = new Date();
    fecha.setDate(fecha.getDate() - 1);
    return fecha.toISOString().slice(0, 10);
  }

  const busquedaayer = async () => {
    setStartDate(ayer());
    setEndDate(ayer());
    busqueda(ayer(),ayer());
  }    

  const busqueda = async (startDate,endDate) => {
    console.log(startDate,endDate)
    setModalDescarga(true)
    setMensajeFallo(false)
    setModalDescargaMensaje('Procesando  ');
      setSearching(true);
        api.getData({docType:docType,folio:folio,startDate:startDate,endDate:endDate}).then(
            (data) => {
                if (__DEV__) {console.log(data);}
                setModalDescarga(false);
                setResponseData(data.data.records);
                setSearching(false);
            }
        ).catch(
            (error)=>{
                setSearching(false);
                setModalDescargaMensaje('Error en la descarga');
                setMensajeFallo(true);
            }
        )
  };

  const descarga = () => {
      api.download({docType:docType,folio:folio,startDate:startDate,endDate:endDate});
  };

  const closeModal = () =>{
    setModalDescarga(false);
  }

  return (
    <>
    <div className="Search">
    <Card >
        <Card.Header>Resumen Diario Documentos Emitidos</Card.Header>
        <Card.Body>
            <Card.Text>
            <Row>
                <Col sm={12} md={12} lg={4}><Form.Label>Tipo&nbsp;Documento</Form.Label><FormControl as="select" value={docType} multiple={false} onChange={(e)=> setDocType(e.target.value)}>
                <option value="">Todos</option>
                <option value="39">Boleta Electrónica</option>
                <option value="41">Boleta Exenta Electrónica</option>
                </FormControl></Col>
                <Col sm={12} md={6} lg={4}><Form.Label>Desde</Form.Label><FormControl type="date" value={startDate} onChange={(e)=>setStartDate(e.target.value)}/></Col>
                <Col sm={12} md={6} lg={4}><Form.Label>Hasta</Form.Label><FormControl type="date" value={endDate} onChange={(e)=>setEndDate(e.target.value)}/></Col>
            </Row>       
            <Row>
                <Col className="mt-3 text-nowrap">
                    <Button variant="primary" disabled={searching} onClick={()=>busqueda(startDate,endDate)}>Buscar<FaSearch className="ml-3"/></Button>
                </Col>
                <Col className="mt-3 text-nowrap">
                    <Button variant="primary" disabled={searching} onClick={busquedaayer}>Ayer<FaCalendar className="ml-3"/></Button>
                </Col>
                <Col className="mt-3 text-nowrap"><Button onClick={descarga}> Descargar <FaDownload color={"white"}></FaDownload></Button></Col>
                <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={() => {setStartDate("");setEndDate("");setDocType("");setFolio("")} }>Limpiar<FaEraser className="ml-3"/></Button>&nbsp;
                </Col>
            </Row>
            </Card.Text>
        </Card.Body>
    </Card>
        <br/>
        <Card >
        <Card.Header>Resultados</Card.Header>
        <Card.Body>
        {responseData && responseData.length>0 && 
        <>
        <TableSimple
        className="resumendiarioboletas"
        headers={ ["Tipo Documento", "Fecha Emision", "Local", "Cajero", "Cantidad", "Valor Neto","Valor IVA","Valor Exento","Valor Total","Valor Efectivo","Valor Tarjeta" ] }
        data={ responseData }
        columns="tipodocumento.fechaemision.local.name.cantidad.valorneto.valoriva.valorexento.valortotal.valorefectivo.valortarjeta"
        perPageItemCount={ 10 }
        partialPageCount={ 3 }
        totalCount={ responseData.length }
        arrayOption={ [['size', 'all', ' ']] }
        nextPageText="Siguiente"
        prePageText="Anterior"
        />
            </>
        }
                </Card.Body>
    </Card>
    </div>

<Modal show={modalDescarga} >
<Modal.Header>
<Modal.Title>Búsqueda boletas diarias</Modal.Title>
</Modal.Header>
<Modal.Body>{modalDescargaMensaje}
{!mensajeFallo && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}
</Modal.Body>
<Modal.Footer>
{mensajeFallo &&<Button variant="secondary" onClick={closeModal}>
    Close
</Button>}
</Modal.Footer>
</Modal>
</>
  );
}


export default Search;
