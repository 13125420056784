/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://5jebms83ra.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:0e9c32f0-513f-4f04-84ac-316392805a44",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VIFDeFHTg",
    "aws_user_pools_web_client_id": "1pis17d10hsj0htb0a5h2odk3m",
    "oauth": {}
};


export default awsmobile;
